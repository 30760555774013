import React from 'react'
import { Link } from 'gatsby'
import styles from './index.module.css'

export default function (props){
  return (
    <div className={ styles.productCategory }>
    <div className={ styles.productCategoryShortDescription }><Link to="/regnklader"> Regnkläder </Link> ett måste som alltid måste finnas på plats till ditt barn. Vår rekommendation är att ha ett extra regnställ som alltid hänger på förskolan. Det finns inga dåliga väder att leka ute i och det är väldigt lätt att glömma regnstället hemma de dagar det inte regnar vid lämning!</div>
    <div className={ styles.productCategoryShortDescription }>Rätt <Link to="/skor"> skor, stövlar eller kängor </Link> på fötterna är viktigt för komforten, kalla eller blöta fötter gör ingen glad</div>
    <div className={ styles.productCategoryShortDescription }><Link to="/klader">Extra kläder</Link>, dagarna på förskolan är ofta fyllda med lek och skoj, ett ombyte bör finnas väl tillhands </div>
    <div className={ styles.productCategoryShortDescription }><Link to="/namnlappar"> Märk </Link> alla kläder och saker som du tar med till förskolan, det är inte lätt för personalen att hålla reda på alla kläder efter alla ombyten! Kläder kommer fortfarande bort men hittar ofta tillbaka med en namnlapp.</div>
    </div>
  )
}
