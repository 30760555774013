import React from 'react';
import Layout from '../components/layout'
import ProductCategories from '../components/productCategories'

export default function(){
  return (
    <>
    <Layout>
    <h3>
    Du har försökt att nå en sida som saknar innehåll, sidan är fortfarande under uppbyggnad och kompletteras med innehåll löpande.
    </h3>
    <ProductCategories>
    </ProductCategories>
    </Layout>
    </>
  )
}
